.BeerItem a:link, .BeerItem a:visited {
    text-decoration: none;
    color: initial;
}

.BeerItem_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgb(255,255,255);
    background: linear-gradient(132deg, rgba(255,255,255,1) 25%, rgba(186,223,219,1) 58%);
    border-radius: 4px;
    -moz-box-shadow:0px 1px 0px 0px rgba(0,0,0,0.7);
	-webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.7);
	box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.7);
}

.BeerItem_logo {
    margin-right: 20px;
}

.BeerItem_title h3, .BeerItem_title p {
    text-align: left;
    margin: 0px;
}

.BeerItem_title h3 {
    font-size: 20px;
}

.BeerItem_title p {
    border-top: 1px solid gray;
}

.BeerItem ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: auto;
}

.BeerItem_stats {
    font-size: 28px;
}

.BeerItem_abv_ibu {
    font-size: 10px;
}
.BeerItem_notes {
    text-align: justify;
    font-size: 12px;
    margin: 4px;
}