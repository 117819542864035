.App {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.fjallaOne {
  font-family: FjallaOne;
}

.raleway_med {
  font-family: Raleway-Medium;
}

.raleway_lgt {
  font-family: Raleway-Light;
}

.nothing {
  font-family: NothingYouCouldDo-Regular;
}

.montserrat {
  font-family: Montserrat-Bold;
}

.montserrat_med {
  font-family: Montserrat-Medium;
}

input, select, textarea {
  font-size: 16px;
}

.App_header {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
}

.App_main {
  margin-top: 90px;
  padding: 0px 10px;
}

ul {
  list-style: none;
  padding-inline-start: 0px;
}

p {
  word-wrap: break-word;
}

.red {
  color: red;
}

.container {
  padding: 10px;
  margin-bottom: 12px;
  background: rgba(252, 249, 234, .8);
  border: 2px solid #f8a978;
  border-radius: 6px;
  -webkit-box-shadow: 3px 2px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 3px 2px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 3px 2px 5px 0px rgba(0,0,0,0.5);
}

.ReviewButton, .ReviewForm_button, .CreateForm_button, .UpdateForm_button  {
	-moz-box-shadow:0px 1px 0px 0px rgba(0,0,0,0.5);
	-webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.5);
	background-color:#f8a978;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #fc9b63;
	cursor:pointer;
	color:#ffffff;
	padding:6px 24px;
	text-decoration:none;
  text-shadow:0px 1px 0px rgba(0,0,0,0.5);
  font-family: Montserrat-bold;
}




