.UpdateReviewForm {
    margin-bottom: 16px;
}

.UpdateReviewForm label {
    display: flex;
    justify-content: space-between;
}

.UpdateReviewForm input[type=range], select, textarea {
    margin: 30px 0;
    width: 100%;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.4);
}

.UpdateReviewForm fieldset {
    border: none;
}

.UpdateReviewForm .slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: opacity .2s;
}
  
.UpdateReviewForm .slider:hover {
    opacity: 1;
}

.UpdateReviewForm .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border: 0;
    background: url('../../utils/images/hop-color-24.png');
    cursor: pointer;
}
  
.UpdateReviewForm .slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border: 0;
    background: url('../../utils/images/hop-color-24.png');
    cursor: pointer;
}

.UpdateReviewForm label {
    font-family: Raleway-Medium;
}