.Header {
    background-color:#badfdb;
}

.Header_Private, .Header_Public {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 5px;
    max-width: 860px;
}

.Header_Public_left {
    padding-right: 10px;
    border-right: black solid 1px;
}

.Header_Public_right {
    margin-left: 10px;
}

.Header a {
    text-decoration: none;
    font-size: 18px;
    font-variant-caps: small-caps;
    color: black;
}