.ResultsPage_create {
    margin-top: 6px;
	-moz-box-shadow:0px 1px 0px 0px rgba(0,0,0,0.5);
	-webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.5);
	background-color:#f8a978;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #fc9b63;
	cursor:pointer;
	color:#ffffff;
	padding:6px 24px;
	text-decoration:none;
    text-shadow:0px 1px 0px rgba(0,0,0,0.5);
    font-family: Montserrat-bold;
}
