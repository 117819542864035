.CreateBeerForm {
    margin-bottom: 16px;
    font-size: 16px;
}

.CreateBeerForm label {
    display: flex;
    justify-content: space-between;
}

.CreateBeerForm input[type=text], input[type=number], input[type=range], select {
    margin: 30px 0;
    width: 100%;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.4);
}

.CreateBeerForm fieldset {
    margin-top: 16px;
    border: none;
}

.CreateBeerForm .slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: opacity .2s;
}
  
.CreateBeerForm .slider:hover {
    opacity: 1;
}

.CreateBeerForm .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border: 0;
    background: url('../../utils/images/hop-color-24.png');
    cursor: pointer;
}
  
.CreateBeerForm .slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border: 0;
    background: url('../../utils/images/hop-color-24.png');
    cursor: pointer;
}

.CreateBeerForm label {
    font-family: Raleway-Medium;
}