.LoginForm {
    margin-bottom: 16px;
}

.LoginForm label {
    display: flex;
    justify-content: space-between;
}

.LoginForm input {
    margin: 8px 0;
    height: 30px;
}

.LoginForm input[type=text], input[type=password] {
    width: 100%;
}

.LoginForm_label {
    font-size: 20px;
}

.LoginForm_button {
    margin-top: 10px;
    width: 200px;
    -moz-box-shadow:0px 1px 0px 0px rgba(0,0,0,0.5);
	-webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.5);
	background-color:#f8a978;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #fc9b63;
	cursor:pointer;
	color:#ffffff;
	padding:6px 24px;
	text-decoration:none;
    text-shadow:0px 1px 0px rgba(0,0,0,0.5);
    font-family: Montserrat-bold;
}