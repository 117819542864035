.Search_container {
    padding-bottom: 10px;
}

.Search {
    width: 100%;
    display: flex;
    justify-content: center;    
}

.Search input[type=text] {
    min-width: 240px;
}

.Search_query {
    width: 100%;
    border: 3px solid black;
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: black;
}

.Search_button {
    width: 40px;
    height: 36px;
    border: 1px solid black;
    background: black;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
}